<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="machine-learning-slider owl-carousel owl-theme">
    <div class="machine-learning-banner ml-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>World’s Leading AI & Machine Learning Company</h1>
                                <p>ML today are able to supply needful of help, information, and positive experience of maintaining intimacy with customers. Eventually, chatbot ideas bring a pleasant experience of all these qualities into the conversation.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>
        
                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ml-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Machine Learning Models For Business Process</h1>
                                <p>ML today are able to supply needful of help, information, and positive experience of maintaining intimacy with customers. Eventually, chatbot ideas bring a pleasant experience of all these qualities into the conversation.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>
        
                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ml-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Use Machine Learning To Augment Your Business Process</h1>
                                <p>ML today are able to supply needful of help, information, and positive experience of maintaining intimacy with customers. Eventually, chatbot ideas bring a pleasant experience of all these qualities into the conversation.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>
        
                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Solutions Area -->
<section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Offer</span>
            <h2>Our Featured Solutions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bxs-analyse'></i>
                    <h3>
                        <a routerLink="/service-details">Robotic Automation</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-shape-circle'></i>
                    <h3>
                        <a routerLink="/service-details">Cognitive Automation</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-badge-check'></i>
                    <h3>
                        <a routerLink="/service-details">Security & Surveillance</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-layer'></i>
                    <h3>
                        <a routerLink="/service-details">Cognitive Engagement</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Solutions Area -->

<!-- About Us -->
<section class="choose-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About Us</span>
                        <h2>Engaging New Audiences Through Smart Approach</h2>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <p>Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc. Donec vitae sapien ut libero venenatis faucibus tempus.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn" routerLink="/">
                            Discover More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/machine-learning/about3.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Us -->

<!-- Ml Services Area -->
<section class="ml-services-area bg-f2f2f7 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Professionals Services For You</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service1.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">IT Professionals</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service2.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Software Engineers</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service3.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Health &amp; Manufacturing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service4.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Security &amp; Surveillance</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service5.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Automatic Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service6.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Data Analysts</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/service-details" class="read-more">
                        Read More
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Ml Services Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Projects</span>
            <h2>Proud Projects That Make Us Stand Out</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>

    <div class="container">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects1.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">Digital Marketing</a></h3>
                    <span class="category">Design</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects1.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects2.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">Design & Development</a></h3>
                    <span class="category">Planing</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects2.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects3.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">Strategic Planing</a></h3>
                    <span class="category">Marketing</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects3.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects4.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">SEO Consultancy</a></h3>
                    <span class="category">Development</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects4.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects5.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">Competitor Analysis</a></h3>
                    <span class="category">Design</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects5.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/projects-image/projects6.jpg" alt="image">

                <div class="projects-content">
                    <h3><a routerLink="/projects-details">Social Media Marketing</a></h3>
                    <span class="category">Development</span>
                </div>

                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects6.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Projects Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Feedback Area -->
<section class="feedback-area feedback-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Free</h3>
                        <p>Get your business up and running</p>
                    </div>

                    <div class="price">
                        <sup>$</sup> 0 <sub>/ Weekly</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Drag & Drop Builder
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Lead Generation & Sales
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Boot & Digital Assistants
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Customer Service
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Up to 1000 Subscribers
                        </li>
                        <li>
                            <i class='bx bxs-x-circle'></i>
                            Unlimited Broadcasts
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Get Started Free! 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Pro</h3>
                        <p>Get your business up and running</p>
                    </div>

                    <div class="price">
                        <sup>$</sup> 149 <sub>/ Monthly</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Drag & Drop Builder
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Lead Generation & Sales
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Boot & Digital Assistants
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Customer Service
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Up to 5000 Subscribers
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Broadcasts
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Get Started Now! 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Get your business up and running</p>
                    </div>

                    <div class="price">
                        <sup>$</sup> 299 <sub> / Annual</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Drag & Drop Builder
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Lead Generation & Sales
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Boot & Digital Assistants
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Customer Service
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Up to 8000 Subscribers
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Broadcasts
                        </li>
                        <li> 
                            <i class='bx bx-badge-check'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Get Started Now! 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">FAQ</span>
            <h2>Frequently Asked Questions</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-img pr-3">
                    <img src="assets/img/faq.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
           
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How to get start with us?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Clients</span>
            <h2>Our Loving Clients</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->

<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/machine-learning/free-trial-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">Get Started Instantly!</span>
                    <h2>Start your free trial</h2>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="email">
                        <button type="submit">Sign Up Free</button>
                    </form>

                    <p class="help-text">Test out the Machine Learning features for 14 days, no credit card required.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Recent Story</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog10.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog11.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog12.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">Here are the 5 most telling signs of micromanagement</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->