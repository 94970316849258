<!-- Start Value Trade Area -->
<div class="value-trade-area">
    <div class="container-fluid">
        <div class="value-trade-slides owl-carousel owl-theme">
            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/REN.png" alt="image">
                <p><b>RenBridges (REN)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/BTC.png" alt="image">
                <p><b>Bitcoin (BTC)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/layer.png" alt="image">
                <p><b>Yearn (YFI)</b> $0.00475688 <span class="color-two"><i class='bx bx-down-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/USDT.png" alt="image">
                <p><b>Tether (USDT)</b> $0.00475688 <span class="color-two"><i class='bx bx-down-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/ETH.png" alt="image">
                <p><b>Ethereum (ETH)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>

            <div class="value-trade-card">
                <img src="assets/img/cryptocurrency-home/value-trade/SNX.png" alt="image">
                <p><b>Synthetix (Snx)</b> $0.00475688 <span><i class='bx bx-up-arrow-alt'></i> +0.017%</span></p>
            </div>
        </div>
    </div>
</div>
<!-- End Value Trade Area -->

<!-- Start Navbar Area -->
<div class="navbar-area navbar-area-with-position-relative">
    <div class="karlson-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Home <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Digital Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-2" routerLinkActive="active" class="nav-link">Marketing Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-3" routerLinkActive="active" class="nav-link">Strategic Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-4" routerLinkActive="active" class="nav-link">Creative Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-5" routerLinkActive="active" class="nav-link">IT Startup</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-6" routerLinkActive="active" class="nav-link">SEO Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-7" routerLinkActive="active" class="nav-link">SaaS Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-8" routerLinkActive="active" class="nav-link">AI & Machine Learning</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-9" routerLinkActive="active" class="nav-link">Portfolio Agency</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-10" routerLinkActive="active" class="nav-link">Cryptocurrency <span class="new">New</span></a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-11" routerLinkActive="active" class="nav-link">Cyber Security <span class="new">New</span></a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/index-12" routerLinkActive="active" class="nav-link">Big Data Solution <span class="new">New</span></a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About Us <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us Style One</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/about-2" routerLinkActive="active" class="nav-link">About Us Style Two</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/services" routerLinkActive="active" class="nav-link">Services Style One</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/services-2" routerLinkActive="active" class="nav-link">Services Style Two</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/services-3" routerLinkActive="active" class="nav-link">Services Style Three</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/service-details" routerLinkActive="active" class="nav-link">Services Details</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Projects <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/projects" routerLinkActive="active" class="nav-link">Projects Style One</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/projects-2" routerLinkActive="active" class="nav-link">Projects Style Two</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/projects-3" routerLinkActive="active" class="nav-link">Projects Style Three</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/projects-4" routerLinkActive="active" class="nav-link">Projects Style Four</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/project-details" routerLinkActive="active" class="nav-link">Projects Details</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Blog <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog Grid</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-2" routerLinkActive="active" class="nav-link">Blog Right Sidebar</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/partner" routerLinkActive="active" class="nav-link">Partner</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/login" routerLinkActive="active" class="nav-link">Login</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/register" routerLinkActive="active" class="nav-link">Register</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/not-found" routerLinkActive="active" class="nav-link">404 Error Page</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div class="others-options">
                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                            <i class="close-btn flaticon-close"></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->