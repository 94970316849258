<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
            </div>
        </div>

        <div class="sidebar-instagram-feed">
            <h2>Instagram</h2>
            <ul>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog4.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog5.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog6.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog7.jpg" alt="image"></a></li>
                <li><a href="#" target="_blank"><img src="assets/img/blog-image/blog8.jpg" alt="image"></a></li>
            </ul>
        </div>

        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+54214587461">542 145 87461</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;karlson.com">hello&#64;karlson.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <span class="close-btn sidebar-modal-close-btn"><i class="flaticon-close"></i></span>
    </div>
</div>
<!-- End Sidebar Modal -->