<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container">
        <div class="main-banner-content">
            <!-- <span class="sub-title">Welcome to The SK HUB</span> -->
            <h1 style="color: white;">Caring for a brighter future</h1>
            
            <!-- <div class="btn-box">
                <a routerLink="/contact" class="default-btn">Contact Us<span></span></a>
            </div> -->
            <div  class="btn-box">
                <h4 style="color: white;">
                    We're Coming Soon..
                </h4>
            </div>
        </div>
        

        
    </div>

    
</div>
<!-- End Main Banner Area -->

