<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a  class="navbar-brand" routerLink="/"><img style="height: 54px;" src="../../../../assets/img/logoo/logobg.png" alt="logo"></a>
                
                
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->