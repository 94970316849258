<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    
                    
                    <img src="../../../../assets/img/logoo/logobg.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <!-- <span>Mon to Fri : 10:00AM - 06:00PM</span> -->
                            <a href="tel:+443300434788">+44 0330 043 4788</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:contact&#64;maarsfostering.com">contact&#64;maarsfostering.com</a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>CONNECT WITH US</span>
                            <ul class="social">
                                <!-- <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                                <li><a href="https://www.linkedin.com/company/maars-fostering-limited" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Technologies</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Contact Us</a></li>
                    </ul>
                </div>
            </div> -->
            
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright &#64;2024 All rights reserved by  <a href="https://maarsfostering.co.uk/" target="_blank">MAARS FOSTERING LIMITED</a></p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">MAARS FOSTERING LIMITED
                            is a Registered Company in England with company number 15425127.
                            Registered office : The Engine House Bexley, 2 Veridion Way, Erith, DA18 4AL</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->