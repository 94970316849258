<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>SEO Services</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>SEO Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">SEO Consultancy</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-search-alt'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Website Optimization</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bullseye'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Target Analysis</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Keyword Research</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-check-shield' ></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Continuous Testing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Marketing Analysis</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-search-alt'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Content Optimization</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-mail-send'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Email Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-data'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">PPC Management</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <!-- Pagination -->
            <div class="col-lg-12 col-sm-12">
                <div class="pagination-area">
                    <a routerLink="/services-3" class="prev page-numbers">
                        <i class="fas fa-angle-double-left"></i>
                    </a>
                    <a routerLink="/services-3" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/services-3" class="page-numbers">3</a>
                    <a routerLink="/services-3" class="page-numbers">4</a>
                    <a routerLink="/services-3" class="next page-numbers">
                        <i class="fas fa-angle-double-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->