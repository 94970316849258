<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Grid</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Grid</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">The Rise Of Smarketing And Why You Need It</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How To Use Music To Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog4.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog5.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Finding the human in technology</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog6.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Ideas people want to spend time with</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog7.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Make the customer the hero of your story</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog8.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Soaring above the rest to provide the best</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog9.jpg" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">The best marketing doesn’t feel like marketing</a></h3>
                        <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->
